import React from "react";
import { useState, useEffect, useContext } from "react";
import { HomeContext, PageContext } from "../../App";
import Allpagemodal from "../../component/modal/allpagemodal";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import {
  NavLink,
  createSearchParams,
  useNavigate,
  Link,
} from "react-router-dom";
import { Squeeze, Squash } from "hamburger-react";

const Header = () => {
  const data = useContext(HomeContext);
  const pageData = useContext(PageContext);
  const headerSectionContent = data?.customization?.headerSection?.content;
  const headerSectionSty = data?.customization?.headerSection?.style;
  const logo = data.logo_img;

  function headerStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .header{
      background: ${headerSectionSty?.headerBg};
      box-shadow: 0px 2px 6px ${headerSectionSty?.headerBottomShaddow};
    }
    .navbar-nav a{
      color: ${headerSectionSty?.linkColor};
    }
    .navbar-nav a:hover{
      color: ${headerSectionSty?.linkHoverColor};
    }
    `;
    document.head.appendChild(style);
  }

  useEffect(() => {
    headerStyle();
  }, [headerSectionSty]);

  const isSmallScreen = window.innerWidth <= 767;
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const [apiModel, setApiModel] = useState([]);
  const headerBg = {};
  const navlinkColor = {};

  const setShow = (arg) => {
    const sectionData = pageData[arg];
    if (sectionData) {
      const { heading, content } = JSON.parse(sectionData);
      setModel({ name: "", title: heading, children: content.text });
      setFlag(true);
    } else {
      console.error(`Section "${arg}" not found in page data.`);
    }
  };
  return (
    <>
      <header className="header" data-scroll>
        {["lg"].map((expand, index) => (
          <Navbar expand={expand} className="nav_bg header" style={headerBg}>
            <div className="container">
              <div className="nav_parent">
                <Navbar.Brand href="/" className="logoCenterText2">
                  <div className="header_logo">
                    <img
                      src={`static/assets/images/${logo ? logo : "logo.svg"}`}
                      alt=""
                    />
                  </div>
                </Navbar.Brand>
                <Navbar.Toggle
                  className="hamburger_main"
                  onClick={() => setIsNavCollapsed(!isNavCollapsed)}
                >
                  <div className="hamburger_sty">
                    <Squash
                      direction="right"
                      size={isSmallScreen ? 28 : 36}
                      color="#222"
                    />
                  </div>
                </Navbar.Toggle>
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  {/* <Offcanvas.Header>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header> */}
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <Link
                        onClick={() => setShow("how_it_works")}
                        style={navlinkColor}
                      >
                        How It Works
                      </Link>
                      <Link
                        style={navlinkColor}
                        onClick={() => setShow("contact")}
                      >
                        Contact Us
                      </Link>
                      <Link
                        style={navlinkColor}
                        onClick={() => setShow("rates")}
                      >
                        Rate & Fees
                      </Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </div>
            </div>
          </Navbar>
        ))}
        <Allpagemodal
          flag={flag}
          setFlagClose={() => setFlag(false)}
          name={model["name"]}
          bodyTitle={model["title"]}
        >
          <>{model["children"]}</>
        </Allpagemodal>
      </header>
    </>
  );
};

export default Header;
