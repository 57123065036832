import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../App";

const HowItWorkSection = () => {
  const data = useContext(HomeContext);
  const howitworksSectionContent = data?.customization?.howitworks?.content;
  const howitworksSectionSty = data?.customization?.howitworks?.style;
  const howItSectionImag = data.section1_img;
  var splitag = howItSectionImag.split(",");
  var howitImg1 = splitag[0];
  var howitImg2 = splitag[1];
  var howitImg3 = splitag[2];
  return (
    <>
      <div className="hiw_wrapper">
        <div className="container">
          <div className="hiw_head">
            <h2 style={{ color: howitworksSectionSty?.headingTextColor }}>
              {howitworksSectionContent?.headingText}
            </h2>
            <p style={{ color: howitworksSectionSty?.subheadingTextColor }}>
              {howitworksSectionContent?.subheadingText}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="hiw_item">
                <div className="hiw_item_head">
                  <img
                    src={`static/assets/images/${
                      howitImg1 ? howitImg1 : "how1.png"
                    }`}
                    className="img-responsive"
                  />
                  <h3
                    style={{ color: howitworksSectionSty?.imageheadingColor }}
                  >
                    {howitworksSectionContent?.imageheading1}
                  </h3>
                </div>

                <p
                  style={{ color: howitworksSectionSty?.imageDescriptionColor }}
                >
                  {howitworksSectionContent?.imageDescription1}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="hiw_item">
                <div className="hiw_item_head">
                  <img
                    src={`static/assets/images/${
                      howitImg2 ? howitImg2 : "how2.png"
                    }`}
                    className="img-responsive"
                  />

                  <h3
                    style={{ color: howitworksSectionSty?.imageheadingColor }}
                  >
                    {howitworksSectionContent?.imageheading2}
                  </h3>
                </div>

                <p
                  style={{ color: howitworksSectionSty?.imageDescriptionColor }}
                >
                  {howitworksSectionContent?.imageDescription2}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="hiw_item">
                <div className="hiw_item_head">
                  <img
                    src={`static/assets/images/${
                      howitImg3 ? howitImg3 : "how3.png"
                    }`}
                    className="img-responsive"
                  />

                  <h3
                    style={{ color: howitworksSectionSty?.imageheadingColor }}
                  >
                    {howitworksSectionContent?.imageheading3}
                  </h3>
                </div>

                <p
                  style={{ color: howitworksSectionSty?.imageDescriptionColor }}
                >
                  {howitworksSectionContent?.imageDescription3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorkSection;
