import React, { useContext, useState } from "react";
import { HomeContext } from "../../App";
import { Accordion } from "react-bootstrap";

const FaqModal = (props) => {
  const FaqModalData = props.modelData.children;
  const data = useContext(HomeContext);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div className="faq_wrapper">
        <Accordion>
          {FaqModalData.map((item, index) => (
            <Accordion.Item key={item.id} eventKey={item.id}>
              <Accordion.Header className="qus_txt">
                {item.qus}
              </Accordion.Header>
              <Accordion.Body>
                <p
                  className="ans_txt"
                  dangerouslySetInnerHTML={{ __html: item.ans }}
                ></p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default FaqModal;
