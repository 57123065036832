import React from "react";
import Header from "../../section/header";
import Banner from "../../section/banner";
import Footer from "../../section/footer";
import HowItWorkSection from "../../section/howItWork";
import CreditScoreSection from "../../section/creditScoreSection";
import HomeCTA from "../../section/homeCta";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <HowItWorkSection />
      <CreditScoreSection />
      <HomeCTA />
      <Footer />
    </>
  );
};

export default Home;
