import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { HomeContext, PageContext } from "../../App";
import useQuery from "../../utils/useQuery";
import { createSearchParams, useNavigate } from "react-router-dom";
import Allpagemodal from "../../component/modal/allpagemodal";
import { Link } from "react-router-dom";

const BannerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const data = useContext(HomeContext);
  const pageData = useContext(PageContext);
  const [prevAmount, setPrevAmount] = useState("");
  const amountRef = useRef();

  const bannerSectionContent = data?.customization.bannerSection.content;
  const bannerSectionSty = data?.customization.bannerSection.style;

  const AmountStyle = {
    borderColor: errors.amount
      ? bannerSectionSty.fieldHoverBorder
      : bannerSectionSty.fieldHoverBorder,
  };
  const EmailStyle = {
    border: "2px solid",
    borderColor: errors.email
      ? bannerSectionSty.fieldHoverBorder
      : bannerSectionSty.fieldHoverBorder,
  };

  function bannerFormStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .banner_content form .form-group input{
      color: ${bannerSectionSty.fieldTextColor};
    }
    .banner_content form .form-group input:hover{
      border: 2px solid ${bannerSectionSty.fieldHoverBorder};
    }
    .banner_content form .form-group input:focus{
      border: 2px solid ${bannerSectionSty.fieldFocusBorder};
    }
    .banner_content form .form-group input:placeholder-shown + label{
      color: ${bannerSectionSty.fieldTextColor};
    }
    .banner_content form .form-group input:focus + label{
      color: ${bannerSectionSty.fieldFocusTextColor};
    }
    .banner_content form .form-group input:hover + label{
      color: ${bannerSectionSty.fieldFocusTextColor};
    }
    .banner_content form .form-group input:not(:placeholder-shown){ 
      border: 2px solid ${bannerSectionSty.fieldFocusBorder};
    }
    .banner_content form .form-group input:not(:placeholder-shown) + label{  
      color: ${bannerSectionSty.fieldFocusTextColor};    
    }
    .banner_content .form_concent{
      color: ${bannerSectionSty.termsTextColor}; 
    }
    .banner_content .form_concent a{
      color: ${bannerSectionSty.discLinkColor};
    }
    .form_submit{
      background: ${bannerSectionSty.ctaBg};
      box-shadow: 0 0 1px 0 ${bannerSectionSty.ctaShadow};
      color: ${bannerSectionSty.ctaTextColor};
    }
    .form_submit:hover {
      background: ${bannerSectionSty.fieldFocusTextColor};
      box-shadow: 0 0 30px 0 ${bannerSectionSty.fieldFocusTextColor};
      color: ${bannerSectionSty.ctaHoverTextColor};
    }
    `;
    document.head.appendChild(style);
  }

  useEffect(() => {
    bannerFormStyle();
  }, [errors.amount, errors.email]);

  const initModelData = { name: "", title: "", children: "" };
  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);

  const maxAmpunt = data.max_amount;

  const query = useQuery();
  const navigate = useNavigate();

  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const max_amount = query.get("max_amount") || "";
  const email = query.get("email") || "";

  const onSubmit = (data) => {
    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
      max_amount,
    };
    const params = {
      email: myData.email || "",
      firstName: myData.firstName || "",
      lastName: myData.lastName || "",
      s1: myData.utm_campaign || "",
      s2: myData.s2 || "",
      s3: myData.s3 || "",
      uid: myData.uid || "",
      utm_medium: myData.utm_medium || "",
      amount: myData.amount || "",
      max_amount: myData.max_amount || "",
    };

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });
  };

  const setShow = (arg) => {
    const sectionData = pageData[arg];
    if (sectionData) {
      const { heading, content } = JSON.parse(sectionData);
      setModel({ name: "", title: heading, children: content.text });
      setFlag(true);
    } else {
      console.error(`Section "${arg}" not found in page data.`);
    }
  };

  const amountInput = watch("amount"); // Watch the "amount" field
  const emailInput = watch("email");

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fields_wrapper">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <input
                  placeholder="Loan Amount "
                  type="tel"
                  id="amount"
                  //style={AmountStyle}
                  // className={`input_field ${errors.amount ? "error" : ""}`}
                  name="name"
                  ref={amountRef}
                  {...register("amount", {
                    required: "This input is required.",
                    pattern: {
                      value: /\b(0|[1-9]\d*)?([05]0)\b/,
                      message: "This value should be a multiple of 50.",
                    },
                    min: {
                      value: 100,
                      message: `This value should be between 100 and ${maxAmpunt}.`,
                    },
                    max: {
                      value: maxAmpunt,
                      message: `This value should be between 100 and ${maxAmpunt}.`,
                    },
                  })}
                />
                <label htmlFor="amount" className="lableSty ">
                  Loan Amount
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name="amount"
                render={({ message }) => (
                  <p className="error_color">{message}</p>
                )}
              />
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input
                  placeholder="Email Addess "
                  type="email"
                  //style={EmailStyle}
                  id="email"
                  // className={`input_field ${errors.email ? "error" : ""}`}
                  name="email"
                  {...register("email", {
                    required: "Email Address is required",

                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  value={email !== "" ? email : undefined}
                />
                <label htmlFor="email" className="lableSty ">
                  Email Address
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="error_color">{message}</p>
                )}
              />
            </div>
          </div>
        </div>
        <p className="form_concent">
          By clicking 'Get Started', you agree to our{" "}
          <Link onClick={() => setShow("policy")}>Privacy Policy</Link>,{" "}
          <Link onClick={() => setShow("terms")}>Terms </Link>,{" "}
          <Link onClick={() => setShow("e_consent")}>E-Consent</Link>,{" "}
          <Link onClick={() => setShow("rates")}>Rates & Fees</Link> and receive
          special offers from us and our marketing partners via email
          communication.
        </p>
        <button type="submit" className="form_submit">
          Get Started
        </button>
      </form>
      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </>
  );
};

export default BannerForm;
