import React, { useContext, useEffect, useState } from "react";
import BannerForm from "../bannerForm";
import { HomeContext } from "../../App";

const Banner = () => {
  const data = useContext(HomeContext);
  const bannerSectionContent = data?.customization.bannerSection.content;
  const bannerSectionSty = data?.customization.bannerSection.style;
  const bannerImage = data.banner_img;
  return (
    <>
      <div
        className="banner_wrapper"
        style={{ background: bannerSectionSty?.backgroundColor }}
      >
        <div className="container">
          <div className="row top_banner_row align-items-lg-center">
            <div className="col-xl-7 col-lg-6">
              <div className="banner_image">
                <img
                  src={`static/assets/images/${
                    bannerImage ? bannerImage : "banner.png"
                  }`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="banner_content">
                <h1
                  style={{ color: bannerSectionSty?.headertextColor }}
                  dangerouslySetInnerHTML={{
                    __html: bannerSectionContent.headingText,
                  }}
                />
                <h6 style={{ color: bannerSectionSty?.paratextColor }}>
                  {bannerSectionContent.subheadingText}
                </h6>
                <BannerForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
