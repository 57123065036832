import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { HomeContext, PageContext } from "../../App";
import Allpagemodal from "../../component/modal/allpagemodal";

const Footer = () => {
  const data = useContext(HomeContext);
  const pageData = useContext(PageContext);
  const footerSectionContent = data.customization.footer.content;
  const footerSectionSty = data.customization.footer.style;
  const initModelData = { name: "", title: "", children: "" };
  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const setShow = (arg) => {
    if (arg === "faq") {
      const faqData = pageData[arg];
      const { heading, content } = JSON.parse(faqData);
      setModel({ name: "", title: heading, children: content.faq });
      setFlag(true);
    } else {
      const sectionData = pageData[arg];
      if (sectionData) {
        const { heading, content } = JSON.parse(sectionData);
        setModel({ name: "", title: heading, children: content.text });
        setFlag(true);
      } else {
        console.error(`Section "${arg}" not found in page data.`);
      }
    }
  };
  const logo = footerSectionContent.footerLogo;
  function footerStyle() {
    const style = document.createElement("style");
    style.textContent = `
    .top_footer ul li a{
      color: ${footerSectionSty.linkColor};
    }
    .top_footer ul li a:hover{
      color: ${footerSectionSty.linkHoverColor};
    }
    `;
    document.head.appendChild(style);
  }

  useEffect(() => {
    footerStyle();
  }, [footerSectionSty]);

  const olaToken = data.ola_script || "";
  let olaimg = "";

  if (olaToken !== "") {
    olaimg = `https://stage.ola-memberseal.org/seal?seal=member&token=${olaToken}`;
  }

  return (
    <>
      <div
        className="page_footer"
        style={{ background: footerSectionSty?.bgColor }}
      >
        <div className="container">
          <div className="top_footer">
            <div className="row">
              <div className="col-lg-2">
                <div className="footer_logo_wrapper">
                  <img
                    src={`static/assets/images/${logo ? logo : "logo.svg"}`}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-10">
                <div className="footer_nav_wrapper">
                  <div className="footer_image_div">
                    <img src={`static/assets/images/${"secure.png"}`} alt="" />
                    <img src={`static/assets/images/${"ssl.png"}`} alt="" />
                    <a
                      href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {olaimg === "" ? (
                        ""
                      ) : (
                        <img
                          src={olaimg}
                          alt=""
                          className="ola_logo"
                          width="40px"
                          height="40px"
                        />
                      )}
                    </a>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={() => setShow("policy")}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => setShow("terms")}>
                        Terms of service
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => setShow("e_consent")}>
                        E-Consent
                      </Link>{" "}
                    </li>
                    <li>
                      <Link onClick={() => setShow("faq")}>FAQ's</Link>{" "}
                    </li>
                    <li>
                      <Link onClick={() => setShow("disclaimer")}>
                        Disclaimer
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => setShow("ccpa")}>
                        {" "}
                        Do Not Sell My Personal Information
                      </Link>{" "}
                    </li>
                    <li>
                      <Link onClick={() => setShow("unsubscribe")}>
                        Unsubscribe
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_text">
            <p
              style={{
                color: footerSectionSty?.bottomParaTextColor,
              }}
            >
              {footerSectionContent?.bottomParaText}
            </p>
          </div>
          <div className="footer_copyright_text">
            <p style={{ color: footerSectionSty?.footerCopyrightTextColor }}>
              {new Date().getFullYear()} {data?.domain_name},{" "}
              {footerSectionContent?.footerCopyrightText}
            </p>
          </div>
        </div>
        <Allpagemodal
          flag={flag}
          setFlagClose={() => setFlag(false)}
          name={model["name"]}
          bodyTitle={model["title"]}
          modelData={model}
        >
          <>{model["children"]}</>
        </Allpagemodal>
      </div>
    </>
  );
};

export default Footer;
